import React from "react"
import styled from "styled-components"
import { Link, navigate } from "gatsby"
import { useState } from "react"
import api from "../components/api"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { useForm } from "react-hook-form"
import Loader from "../components/shared/loader"

const Style = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 85vh;

  form {
    button {
      font-family: "Work Sans";
      font-size: 18px;
      color: white;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 16px;
      width: 100%;
      height: 50px;
      left: 0px;
      top: 226px;
      background: #00ca42;
      border-radius: 60px;
      flex: none;
      align-self: center;
      flex-grow: 0;
      margin: 16px 0px;
      border: none;
      cursor: pointer;
      &:hover {
        background: rgb(50, 205, 50);
      }

      /* @media (max-width: 400px) {
      content: "Complete";
    }
    content: "Complete"; */
    }
  }
  .card-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 520px;
    height: 376px;
    background: #ffffff;
    box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    @media (max-width: 560px) {
      width: 90%;
    }

    .form-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 268px;
      width: 384px;
      @media (max-width: 560px) {
        width: max-content;
      }

      .form-name {
        align-self: center;
        width: 295px;
        height: 40px;
        font-family: Work Sans;
        font-style: normal;
        font-weight: 500;
        font-size: 36px;
        line-height: 40px;
        text-align: center;
        color: #111111;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 32px 0px;

        @media (max-width: 560px) {
          font-size: 26px;
          line-height: 30px;
        }
      }

      label {
        align-self: flex-start;
        font-family: Work Sans;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #111111;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 8px 0px;
        @media (max-width: 560px) {
          align-self: center;
          width: 209px;
        }
      }

      input {
        display: flex;
        flex-direction: row;
        font-family: Roboto;
        align-items: flex-start;
        padding: 16px;
        width: 384px;
        height: 51px;
        border: 1px solid #d8d8d8;
        box-sizing: border-box;
        border-radius: 5px;
        flex: none;
        align-self: stretch;
        flex-grow: 0;
        margin: 8px 0px;
        @media (max-width: 560px) {
          align-self: center;
          width: 209px;
        }
      }
      .alert-fail {
        font-family: Work Sans;
        width: 273px;
        height: 24px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: #e44331;
        margin: 0;
        padding: 0;
        align-self: flex-start;
        margin-left: 0px;
        @media (max-width: 675px) {
          width: 273px;
          overflow: hidden;
        }
      }
      button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px;
        width: 384px;
        height: 50px;
        background: #00ca42;
        color: white;
        border-radius: 60px;
        border: none;
        flex: none;
        align-self: stretch;
        flex-grow: 0;
        margin: ${({ fail }) => (fail ? true : "12px 0px")};
        cursor: pointer;

        &:hover {
          background: rgb(50, 205, 50);
        }
        @media (max-width: 560px) {
          align-self: center;
          width: 209px;
        }
      }
      .back {
        width: 115px;
        height: 21px;
        font-family: Work Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        color: #007de6;
        flex: none;
        flex-grow: 0;
        margin: 24px 0px;
      }
    }
    .success-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 520px;
      height: 376px;
      background: #ffffff;
      box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      @media (max-width: 560px) {
        width: 100%;
      }

      .success-name {
        width: 305px;
        height: 40px;
        font-family: Work Sans;
        font-style: normal;
        font-weight: 500;
        font-size: 36px;
        line-height: 40px;
        text-align: center;
        color: #111111;
        flex: none;
        flex-grow: 0;
        margin: 24px 0px;
      }

      .success-text {
        width: 305px;
        height: 54px;
        font-family: Work Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        color: #222222;
        flex: none;
        align-self: center;
        flex-grow: 0;
        margin: 24px 0px;
      }
    }
  }
`

function CreateAccount() {
  //state that manages if button is disabled
  const [isDisabled, setIsDisabled] = useState(false)

  //loader state
  const [isLoading, setIsLoading] = useState(false)

  //error messages
  const [failEmailValidation, setFailEmailValidation] = useState(false)
  const [failAlreadyExists, setFailAlreadyExists] = useState(false)
  //success message
  const [success, setSuccess] = useState(false)

  //checking if window exsist - because of compiler
  if (typeof window !== "undefined") {
    if (JSON.parse(localStorage.getItem("isLogged")) === true) {
      navigate("/app/dashboard")
    }
  }

  //submit function
  //submit popup function
  function onSubmit(data) {
    const regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (data.Email !== null && regexEmail.test(data.Email)) {
      setIsDisabled(true)
      const encoded = encodeURIComponent(data.Email)

      api
        .get(
          `/api/v1/pdfpro/email-on-product/?email=${encoded}|pdfpro&product_code=pdf`
        )
        .then(res => {
          console.log(res)
          if (
            res.data.licenses_on_product_count.any === 0 &&
            res.data.licenses_on_product_count.trial < 2
          ) {
            api
              .post("/api/v1/pdfpro/invite-user/", {
                email: data.Email,
                // product_code: "pdf",
                company_code: "pdfpro",
              })
              .then(res => {
                setFailEmailValidation(false)
                setFailAlreadyExists(false)
                setIsDisabled(false)
                setIsLoading(false)
                setSuccess(true)

                // Universal Analytics(GA3) Event
                window.dataLayer = window.dataLayer || []
                window.dataLayer.push({
                  event: "buttonClick",
                  category: "Enter Email on pdfpro.com/register",
                  action: "Click",
                  label: "Email address entered on pdfpro.com/register",
                })

                // GA4 Event
                window.dataLayer = window.dataLayer || []
                window.dataLayer.push({
                  event: "EmailValidated",
                  category: "EmailValidated",
                  action: "Try PDF Pro - Email validated",
                  label: "Try PDF Pro - Email validated",
                })

                // GA4 Event - staging
                window.dataLayer = window.dataLayer || []
                window.dataLayer.push({
                  event: "GA4 - Custom Event",
                  eventName: "Email validation/Client-side validation",
                  action:
                    "User entered email address, email address is validated",
                })
              })
              .catch(err => {
                console.log(err)
                setIsLoading(false)
                setIsDisabled(false)
              })
          } else {
            setIsLoading(false)
            setIsDisabled(false)
            setError("EmailExists", {
              type: "EmailExists",
              message: "Email is already in use!",
            })
          }
        })
        .catch(err => {
          console.log(err)
          setIsLoading(false)
          setIsDisabled(false)
        })
    } else {
      setIsLoading(false)
      setIsDisabled(false)
      setError("IncorrectEmail", {
        type: "IncorrectEmail",
        message: "Incorrect email! Try again.",
      })
    }
  }

  const {
    register,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm()

  return (
    <>
      <Seo title="Create Account" />
      <Style>
        <div className="card-container">
          {isLoading === true && <Loader />}
          {success === false && isLoading === false && (
            <form className="form-container" onSubmit={handleSubmit(onSubmit)}>
              <h1 className="form-name">Create Account</h1>
              <label htmlFor="email2">Email</label>
              <input
                type="email2"
                name="email2"
                placeholder="Enter email"
                {...register("Email", {
                  required: true,
                  onChange: () => {
                    clearErrors("IncorrectEmail")
                    clearErrors("EmailExists")
                  },
                })}
              />

              {Object.values(errors).map(prop => {
                return <div className="alert-fail">{prop.message}</div>
              })}
              <button disabled={isDisabled}>Create account</button>

              <Link className="back" to="/login">
                Back to login
              </Link>
            </form>
          )}
          {success && (
            <div className="success-box">
              <StaticImage
                src="../images/success-img.png"
                width={33}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF", "png"]}
                loading="eager"
                placeholder="blurred"
                alt="success-img"
              />
              <h2 className="success-name">Check your inbox</h2>
              <span className="success-text">
                You will recieve your initial password!
              </span>
            </div>
          )}
        </div>
      </Style>
    </>
  )
}

export default CreateAccount
